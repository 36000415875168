import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faLinkedinIn , faYoutube , faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notifications from "vue-notification"
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import VueAxios from "vue-axios";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

//Install FontAwesome
library.add(faSearch, faFileSignature, faFacebookF, faLinkedinIn, faYoutube, faInstagram)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Install Notification
Vue.use(Notifications);

//Install VeeValidate globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Install Axios
Vue.use(VueAxios, axios);

// global variable
Vue.prototype.$appName = process.env.VUE_APP_NAME;
Vue.prototype.$appNotificationSuccessTitle = process.env.VUE_APP_NOTIFICATION_SUCCESS_TITLE;
Vue.prototype.$appNotificationErrorTitle = process.env.VUE_APP_NOTIFICATION_ERROR_TITLE;
Vue.prototype.$appNotificationPosition = process.env.VUE_APP_NOTIFICATION_POSITION;
Vue.prototype.$appNotificationDuration = Number(process.env.VUE_APP_NOTIFICATION_DURATION);

Vue.config.productionTip = false
new Vue({
    created() {
        this.init();
    },
    methods: {
        init(){
            this.setPageTitle();
        },

        setPageTitle(page?: string) {
            let title = this.$appName;
            if (page) {
                title = `${page} - ${title}`;
            }
            document.title = title;
        },
    },
    router,
    store,
    render: h => h(App)
}).$mount("#app");
