import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/Home.vue"),
        meta: { auth: false }
    },

    {
        path: "/404",
        name: "NotFound",
        component: () =>
            import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
        meta: { auth: false }
    },

    {
        path: '*',
        redirect: '/404'
    },

];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
